<template>
  <div class="h-100 w-100 d-flex"> 

    <div class="d-flex flex-column h-100 w-100 p-1">
      <!-- Options top -->
      <div class="d-flex justify-content-between align-items-center mb-1">
        <b-button variant="outline-secondary" class="size-button-center" @click="$emit('slide_window')">
           <span><feather-icon icon="MenuIcon"/></span>
        </b-button>
        <div class="d-flex">
  
          <b-button-group class="ml-2 body-button-center max-width-disabled">
            <b-button
            :class="`${!grid_size_active ? 'active-button-center' : 'color-btn-center'} size-button-center`"
            @click="grid_size_active =  false"
            >
              <b-icon icon="grid3x3-gap-fill" font-scale="1.5" variant="dark"></b-icon>
            </b-button>
            <b-button
            :class="`${grid_size_active ? 'active-button-center' : 'color-btn-center'} size-button-center`"
            @click="grid_size_active = true"
            >
              <b-icon icon="grid-fill" font-scale="1.5" variant="dark"></b-icon>
            </b-button>
          </b-button-group>
  
          <b-dropdown
            variant="outline-secondary" 
            class="m-5rem size-drop-responsive"
            no-caret
            right
          > 
            <template #button-content><b-icon icon="three-dots-vertical" font-scale="1.3" variant="dark"/></template> 
            <b-dropdown-item @click="grid_size_active = !grid_size_active">
              <div class="d-flex justify-content-between">
                <span class="mr-2">{{$t('search.gridSize')}}</span>
                <b-icon :icon="grid_size_active ? 'grid-fill' : 'grid3x3-gap-fill'" font-scale="1" variant="dark"/>
              </div>
            </b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item disabled>
              <div class="d-flex justify-content-between">
                <span>{{$t('tracking.export')}}</span>
                <span><feather-icon icon="ShareIcon"/></span>
              </div>
            </b-dropdown-item>
          </b-dropdown>
  
          <b-button 
            variant="outline-secondary" 
            class="size-button-center m-5rem icon-setting-sider"
            @click="sider_option_ai = !sider_option_ai"
          >
            <b-icon icon="gear-wide-connected" font-scale="1.3" variant="dark"></b-icon>
          </b-button>
        </div>
      </div>
      <!----------------->
      <div class="flex-grow">
        <b-row :class="`${getGrid()} grid-container m-0`">
          <div
            v-for="(data , index) in data_img_ai.length > 0 ? data_img_ai : setting_selected.number_image_ai"
            :class="'container-aspect-ratio'"
            :key="index"
            :ref="`aspect_ratio_box_${index}`"
          >           
            <div class="h-100 aspect-ratio" @click="data_img_ai.length > 0 ? gridColSpan(index) : ''">
              <img v-if="data_img_ai.length === 0" :src="require('@/assets/images/svg/stars.svg')" class="img-stars"/>
              <div v-else-if="data_img_ai.length > 0 && !loader_image_search" class="w-100 h-100 position-relative">
                <div class="container-dropdown-image">
                  <b-dropdown
                    no-caret
                    right
                    variant="link"
                    class="avenir-medium dropdown-options-image"
                  > 
                    <template #button-content><b-icon icon="three-dots-vertical" variant="white"/></template> 
                    <b-dropdown-item @click="downloadFile(data.url)"><feather-icon icon="DownloadIcon"></feather-icon>  Download Image</b-dropdown-item>
                  </b-dropdown>
                </div>
                <img :src="data.url" class="img-ia"/>
                <div class="container-ia-svg">
                  <img :src="require('@/assets/images/icons/ia.svg')" :alt="`ia-svgd`" class="ia-svg-icon">
                </div>
              </div>
              <b-skeleton class="skeleton-loading-ia" v-if="loader_image_search"></b-skeleton>
            </div>
          </div>
        </b-row>
      </div>
  
      <!-- Container search input -->
      <div class="background-input-botton" v-if="Object.keys(history_images).length === 0">
        <div class="d-flex justify-content-between align-items-center div-input-button">
          <b-form-input :placeholder="placeholder_text" class="size-input" v-model="setting_selected.promp_ai" autocomplete="off" ref="textInput" />
          <b-button
          :disabled="setting_selected.promp_ai === null || setting_selected.promp_ai.length < 3"
          class="generate-img-ai"
          @click="generateImage()"
          v-if="!loader_image_search"
          >
          {{ data_img_ai.length > 0 ? $t('search.regenerateImg') : $t('search.generateImg')}}
          <b-badge class="badge-button-ia"><feather-icon icon="CodesandboxIcon" class="icon-ia"></feather-icon>{{ getCredits() >= 0 ? getCredits() : 'Insufficient Credits' }}</b-badge>
          </b-button>
          <b-skeleton v-else type="button" class="generate-img-ai"></b-skeleton>
        </div>
      </div>
      <div v-else class="d-flex justify-content-between border-top pt-2">
        <div>
          <b-button variant="outline-secondary" @click="backToHistory()">Back</b-button>
        </div>
        <div>
          <b-button variant="outline-secondary" @click="$emit('change_uuid', 'backward')">
            <feather-icon icon="ChevronLeftIcon"></feather-icon>
          </b-button>
          <b-button variant="outline-secondary" class="ml-1" @click="$emit('change_uuid', 'forward')">
            <feather-icon icon="ChevronRightIcon"></feather-icon>
          </b-button>
        </div>
      </div>
      <!-- -------------------------- -->
    </div>

        <!-- Options AI -->
    <div class="width-right-area">
      <sider-right-options 
        class="w-100" 
        ref="sider_data" 
        :loader_image_search="loader_image_search" 
        :setting_selected="setting_selected" 
        :prompt_builder="prompt_builder"
        :options_imagine="options_imagine"
        :key="update_sidebar"
        v-if="Object.keys(history_images).length === 0"
      />
      <sider-right-history
        class="w-100" 
        :history_images="history_images"
        @edit_history="$emit('edit_history')"
        v-else
      ></sider-right-history>
    </div>
    <!-- ---------- --> 
      <!-- Responsive sider-right options -->
    <b-sidebar v-model="sider_option_ai" backdrop shadow right class="sidebar-right-responsive-options-models-ia"> 
      <sider-right-options 
        v-if="Object.keys(history_images).length === 0" 
        ref="sider_data" 
        :loader_image_search="loader_image_search" 
        :setting_selected="setting_selected" 
        :is_sidebar="true" 
        :prompt_builder="prompt_builder"
        :options_imagine="options_imagine"
      />
      <sider-right-history
        class="w-100" 
        :history_images="history_images"
        @edit_history="$emit('edit_history')"
        v-else
      ></sider-right-history>
    </b-sidebar>
    <!-- ------------------------------ -->
  </div>
</template>
  
<script>
  
import {
  BRow,
  BDropdown,
  BDropdownItem,
  BSkeleton,
  BButton,
  BButtonGroup,
  BDropdownDivider,
  BFormInput,
  BSidebar,
  BBadge,
} from 'bootstrap-vue';
import { createImage, getDetailHistory, createVariationsImage } from '@/services/ia'
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');

  
export default {
  name: 'containerGrid',
  components: {
    BRow,
    BDropdown,
    BDropdownItem,
    BSkeleton,
    BButton,
    BButtonGroup,
    BDropdownDivider,
    BFormInput,
    BSidebar,
    BBadge,
    siderRightOptions: () => import('./siderbars/siderRightOptions.vue'),
    siderRightHistory: () => import('./siderbars/siderRightHistory.vue')
  },
  props: {
    history_images: {
      type: Object,
      default: () => {
        return {}
      },
    },
    credits_avaible: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      placeholder_text: '',
      index_ratio_selected: null,
      grid_size_active: true,
      data_img_ai: [],

      prompt_builder: {
        camera: {
          models: {
            hardware: "",
            lens: "",
            aperture: "",
            framing: ""
          }
        },
        art_style: {
          models: {
            primary_style: "",
            secondary_style: "",
            perspective: "",
            medium: "",
            substrate: ""
          }
        },
        artist: {
          models: {
            genre: "",
            style_painter: ""
          }
        },
        scene: {
          models: {
            palette: "",
            lighting: "",
            aesthetic: "",
            emotion: "",
            atmosphere: "",
            background: ""
          }
        },
        technical: {
          models: {
            resolution: "",
            renderer: "",
            website: ""
          }
        },
        modifiers: {
          models: {
            concepts: "",
            thematics: "",
            hallucinations: "",
            distortions: ""
          }
        }
      },
      setting_selected: {
        promp_ai: null,
        ia_selected: this.$t('search.ia_options')[0],
        number_image_ai: 1,
        negative_prompt: undefined,
        size_images_selected: this.$t('search.size_images')[0],
        file_variants: null,
      },
      options_imagine: {
        model: {value: 33, category: 'imagine_v5', name: 'Imagine V5'},
        aspect_ratio: '1:1',
        art_style: null,
        cfg: 3,
        steps: 30,
        seed: null,
        high_res_results: 0,
      },
      image_active_slide: false,
      loader_image_search: false,
      sider_option_ai: false,
      update_sidebar: false,
    }
  },
  created() {
    if (Object.keys(this.history_images).length > 0) this.data_img_ai = this.history_images.response_server.data;

    if (this.$route.params.uuid) {
      this.getDetailHistoryImages()
    }
    this.$root.$on('images_created_ws_or_variant', (data) => {
      this.data_img_ai = data.body.response_server.data;
      this.loader_image_search = false;
    });
  },
  mounted() {
    const placeholder_text = this.$t('search.describeImage');
    const speed = 50;
    this.animatePlaceholder(placeholder_text, speed);
    this.$refs.textInput.$el.addEventListener('focus', () => {
      this.animatePlaceholder(placeholder_text, speed);
    });
  },
  methods: {
    async typeWriter(text, index, speed) {
      if (index < text.length) {
        this.placeholder_text += text.charAt(index);
        await this.sleep(speed);
        await this.typeWriter(text, index + 1, speed);
      }
    },
    async animatePlaceholder(text, speed) {
      this.placeholder_text = '';
      await this.typeWriter(text, 0, speed);
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    getDetailHistoryImages() {
      getDetailHistory(this.$route.params.uuid).then((response) => {
        this.data_img_ai = response.response_server.data;
        this.setting_selected.promp_ai = response.original_prompt;
        this.setting_selected.number_image_ai = response.num_images;
        this.setting_selected.negative_prompt = response.data.negative_prompt;
        this.setting_selected.file_variants = response.data.origin_image;
        this.update_sidebar = !this.update_sidebar

        setTimeout(() => {
          this.setting_selected.size_images_selected = this.splitSizeString(response.size_images, response.data.model)
        }, 200);
        if (response.data.model) this.setting_selected.ia_selected = this.$t('search.ia_options').find((ia_op) => ia_op.value === response.data.model)
        this.formatPrompt(response.data);
      })
    },
    formatPrompt(data) {
      for (const value in data) {
        const evit = ['negative_prompt', 'prompt', 'model']
        if (data[value] && evit.indexOf(value) === -1) {
          const skeleton = this.$t('search.prompts_skeleton')[value]
          this.prompt_builder[skeleton[0]].models[skeleton[1]] = data[value];
        }
      }
    },
    splitSizeString(size, model) {
      const splitted_size =  parseInt(size.split('x')[0]);
      if (model !== 'dall-e') return this.$t('search.size_images_xl').find((size) =>  size.value_width === splitted_size)
      else return this.$t('search.size_images').find((size) =>  size.value_width === splitted_size)
    },
    getGrid() {
      if (this.image_active_slide && this.grid_size_active) {
        return 'grid-active-left'
      } else if (!this.image_active_slide && this.grid_size_active) {
        return 'grid-desactive-left'
      } else if (!this.grid_size_active) {
        return 'grid-long-left'
      }
    },
    getCredits() {
      const credits_map = {256: 1, 512: 2, 1024: 3};
      const { ia_selected, size_images_selected, number_image_ai } = this.setting_selected;
      const credit = ia_selected.value === 'dall-e' ? credits_map[size_images_selected.value_width] : 10;
      return this.credits_avaible >= credit * number_image_ai ? credit * number_image_ai : -1;
    },
    getDataForImagineModel() {
      const data = {
        model: this.setting_selected.ia_selected.value,
        prompt: this.setting_selected.promp_ai,
        number: 1,
        negative_prompt: this.setting_selected.negative_prompt,
        aspect_ratio: this.options_imagine.aspect_ratio,
        style_id: this.options_imagine.model.value,
        art_style: this.options_imagine.art_style ? this.options_imagine.art_style.name : null,
        cfg: this.options_imagine.cfg,
        steps: this.options_imagine.steps,
        seed: this.options_imagine.seed,
        high_res_results: this.options_imagine.high_res_results ? 1 : 0,
      }
      return data
    },
    generateImage() {
      
      if (this.getCredits() >= 0 && !this.setting_selected.file_variants) {
        this.loader_image_search = true;

        // Get data for rests of models or imagine model
        const data = this.setting_selected.ia_selected.value !== 'imagine' ? {
          model: this.setting_selected.ia_selected.value,
          prompt: this.setting_selected.promp_ai,
          number: this.setting_selected.number_image_ai,
          width: this.setting_selected.size_images_selected.value_width,
          height: this.setting_selected.size_images_selected.value_height,
          negative_prompt: this.setting_selected.negative_prompt,
          hardware_camera: this.prompt_builder.camera.models.hardware,
          lens_camera: this.prompt_builder.camera.models.lens,
          aperture_camera: this.prompt_builder.camera.models.aperture,
          framing_camera: this.prompt_builder.camera.models.framing,
          primary_style: this.prompt_builder.art_style.models.primary_style,
          secondary_style: this.prompt_builder.art_style.models.secondary_style,
          perspective: this.prompt_builder.art_style.models.perspective,
          medium: this.prompt_builder.art_style.models.medium,
          substrate: this.prompt_builder.art_style.models.substrate,
          artist_genre: this.prompt_builder.artist.models.genre,
          artist_style: this.prompt_builder.artist.models.style_painter,
          scene_palette: this.prompt_builder.scene.models.palette,
          scene_lighting: this.prompt_builder.scene.models.lighting,
          scene_aesthetic: this.prompt_builder.scene.models.aesthetic,
          scene_emotion: this.prompt_builder.scene.models.emotion,
          scene_atmosphere: this.prompt_builder.scene.models.atmosphere,
          scene_background: this.prompt_builder.scene.models.background,
          technical_resolution: this.prompt_builder.technical.models.resolution,
          techinical_renderer: this.prompt_builder.technical.models.renderer,
          technical_website: this.prompt_builder.technical.models.website,
          concept: this.prompt_builder.modifiers.models.concepts,
          thematics: this.prompt_builder.modifiers.models.thematics,
          hallucinations: this.prompt_builder.modifiers.models.hallucinations,
          distorcions: this.prompt_builder.modifiers.models.distortions
        } : this.getDataForImagineModel();

        const formated_data = {}
        for (const i in data) {
          if (data[i]) formated_data[i] = data[i];
        }
        createImage(formated_data).then((response_gpt) => {
          if (response_gpt.status >= 400) {
            this.loader_image_search = false;

            loadToastificationContent().then((module) => {
              this.$toast({
                component: module.default,
                position: 'top-right',
                props: {
                  title: 'Error',
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                  text: this.$t('search.mesageError'),
                },
              });
            })
          } else {
            this.$emit('update_credits', this.getCredits())
            this.$root.$emit('update_credits_drop', this.getCredits())
          }
        })
      } else if (this.getCredits() >= 0 && this.setting_selected.file_variants) {
        this.loader_image_search = true
        const data = new FormData();
        data.append('model', this.setting_selected.ia_selected.value)
        data.append('prompt', this.setting_selected.promp_ai);
        data.append('number', this.setting_selected.number_image_ai);
        data.append('width', this.setting_selected.size_images_selected.value_width);
        data.append('height', this.setting_selected.size_images_selected.value_height);
        data.append('image', this.setting_selected.file_variants);

        createVariationsImage(data).then((response) => {
          if (response.status >= 400) {
            this.loader_image_search = false;

            loadToastificationContent().then((module) => {
              this.$toast({
                component: module.default,
                position: 'top-right',
                props: {
                  title: 'Error',
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                  text: this.$t('search.mesageError'),
                },
              });
            })
          } else {
            this.$emit('update_credits', this.getCredits())
            this.$root.$emit('update_credits_drop', this.getCredits())
          }
        });
      } else this.$emit('show_modal_update_suscription')
    },
    backToHistory() {
      this.$router.push({ name: 'brandme_AI', params: { section: 'histories', category: 'image'}});
      this.$emit('back_histories')
    },
    gridColSpan(index) {
      if (this.index_ratio_selected !== index) {
        this.data_img_ai.forEach((_, inner_index) => {
          this.$refs[`aspect_ratio_box_${inner_index}`][0].classList.remove('grid-col-span')
        })
      }
      this.index_ratio_selected = index;
      this.$refs[`aspect_ratio_box_${index}`][0].classList.toggle('grid-col-span')
    },
    downloadFile(url) {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.responseType = 'blob';
      xhr.onload = () => {
        if (xhr.status === 200) {
          const blob = xhr.response;
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          const splitted = url.split('/');
          link.download = splitted[splitted.length - 1];
          link.click();
        }
      };
      xhr.onerror = () => {
        if (xhr.status === 0)
          window.open(url, '_blank');
      };
      xhr.send();
    },
    
  }
}
</script>
<style lang="scss" scoped>
.size-input::placeholder {
  color: #70747c;
}
.width-right-area {
  width: 420px;
  flex-shrink: 0;
}
.flex-grow {
  flex-grow: 1;
  overflow-y: scroll;
  overflow-x: hidden;
}
.size-button-center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1rem;
  height: 3rem;
}
.m-5rem{
  margin-left: 0.5rem;
}
.body-button-center{
  position: relative;
  background-color: #e9ecef;
  padding: .25rem .25rem;
  border-radius: .375rem;
}
.active-button-center{
  background-color: #fff !important;
  border-color: #fff !important;
  border-radius: 10% !important;
  box-shadow: 0rem 0.1875rem 0.375rem 0rem rgba(108,117,125,.25);
}
.color-btn-center{
  background-color: #e9ecef !important;
  border-color: #e9ecef !important;
  box-shadow: none !important;
}
.size-drop-responsive{
  display: none;
}
.icon-setting-sider{
  background-color: #e9ecef;
  display: none;
}
.background-input-botton{
  width: 100%;
  padding: 1em 0 0 0;
}
.div-input-button{
  backdrop-filter: blur(2px);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  padding: 0.5em;
  .badge-button-ia {
    display: flex;
    align-items: center;
    margin-left: 0.5em;
    line-height: 10px;
    background-color: #40736E;
    .icon-ia {
      width: 14px;
      height: 14px;
      margin-right: 0.2em;
    }
  }
}
.size-input{
  background-color: transparent;
  height: 100%;
  width: 80%;
  font-size: 15px;
  font-weight: bold;
  border: none;
  box-shadow: none !important;
  font-family: monospace !important;

  @media(max-width: 500px) {
    font-size: 14px;
  }
}
.generate-img-ai{
  height: 42px;
  display: flex;
  align-items: center;
  background: linear-gradient(to left bottom, rgb(124, 214, 175), rgb(62, 123, 160));
}
.grid-container {
  display: grid;
  grid-gap: 14px;
  grid-auto-flow: dense;
  .grid-col-span {
    grid-column-end: span 2;
    grid-row-end: span 2;
  }
}
.grid-long-left {
  grid-template-columns: repeat(8, 1fr);
}
.grid-active-left {
  grid-template-columns: repeat(4, 1fr);
}
.grid-desactive-left {
  grid-template-columns: repeat(5, 1fr);
}
.aspect-ratio {
  aspect-ratio: 1 / 1;
  background: linear-gradient(0deg, rgba(250,250,250,1) 0%, rgba(219,219,219,1) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  
  .skeleton-loading-ia {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 100;
  }
  .img-stars {
    width: 25%;
    height: 25%;
    color: #d3d3d3;
  }
  .img-ia {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .container-ia-svg {
    position: absolute;
    width: 5%;
    height: 5%;
    right: 5%;
    background-color: white;
    bottom: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    .ia-svg-icon {
      width: 170%;
      height: 170%;
    }
  }
}
.container-dropdown-image {
  background-color: rgba(0, 0, 0, 0.699);
  position: absolute;
  top: 5%;
  border-radius: 0.5em;
  right: 5%;
  transform: translateX(50px);
}
.container-aspect-ratio {
  &:hover {
    .container-dropdown-image {
      animation: dropdown-animation 300ms forwards;
    }
  }
  &:not(:hover) { 
    .container-dropdown-image {
      animation: dropdown-animation-back 300ms forwards;
    }
  }
}
@media (max-width: 1199px) {
  .icon-setting-sider{
    display: flex;
  }
  .width-right-area {
    display: none;
  }
}
@media (max-width: 1000px) {
  .grid-active-left {
    grid-template-columns: repeat(3, 1fr);
  }
  .grid-desactive-left {
    grid-template-columns: repeat(4, 1fr);
  }
  .grid-long-left {
    grid-template-columns: repeat(7, 1fr);
  }
}

@media (max-width: 770px) {
  .max-width-disabled{
    display: none;
  }
  .size-drop-responsive{
    display: flex;
  }
}
@media (max-width: 765px) {
  .grid-active-left, .grid-desactive-left {
    grid-template-columns: repeat(2, 1fr);
  }
  .grid-long-left {
    grid-template-columns: repeat(5, 1fr);
  }
}
@media (max-width: 515px) {
  .grid-active-left, .grid-desactive-left {
    grid-template-columns: repeat(1, 1fr);
  }
  .grid-long-left {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 350px) {
  .grid-long-left {
    grid-template-columns: repeat(2, 1fr);
  }
}

@keyframes dropdown-animation {
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes dropdown-animation-back {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(50px);
    opacity: 0;
  }
}
</style>
<style lang="scss">
.sidebar-right-responsive-options-models-ia .b-sidebar {
  width: 400px;
}
.dropdown-options-image .btn {
  padding: 0.5em !important;
}
</style>